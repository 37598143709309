import consumer from "./consumer"
import {newActivity} from "../helpers/portal_helpers";

let portalChannelSubscription = null;

export function createPortalChannelSubscription(id) {
  if (portalChannelSubscription) {
    console.log("Using existing PortalChannel subscription");
    return portalChannelSubscription;
  }

  console.log("Creating new PortalChannel subscription");
  portalChannelSubscription = consumer.subscriptions.create({ channel: "PortalChannel", id: id }, {
    connected() { },
    disconnected() { },
    received(data) {
      console.log("PortalChannel received data");
      if (data["method"] === "new_activity") {
        newActivity(data);
      }
    },
  });

  return portalChannelSubscription;
}

document.addEventListener("turbolinks:load", () => {
  const channelContainer = document.querySelector(".portal-channel-container[data-portal-id]");
  const activityContainer = document.querySelector(".portal-activity-container");

  if (channelContainer && activityContainer) {
    const id = channelContainer.dataset.portalId;
    createPortalChannelSubscription(id);
  }
});

// @dev For testing purposes
export { newActivity, consumer };