import { Controller } from "@hotwired/stimulus";
import { displayFlashMessage } from "utils/flash";

export default class extends Controller {
  static targets = [ "source" ]
   copy(event) {
    event.preventDefault();
    try {
       navigator.clipboard.writeText(this.sourceTarget.value);
       displayFlashMessage("Copied to clipboard", "is-success");
    } catch (err) {
      console.error("Unable to copy", err);
    }
  }
}


