import consumer from "./consumer"

import { checkIn, updateScanData } from "../helpers/check_in_helpers";
import { updateBooking } from "../helpers/booking_helpers";
import { updateInvite } from "../helpers/invite_helpers";
import { updateGuest } from "../helpers/guest_helpers";
import { updateBookingLink } from "../helpers/booking_link_helpers";
import { handlePayment } from "../helpers/payment_helpers";


let spaceChannelSubscription = null;

export function createSpaceChannelSubscription(id) {
  if (spaceChannelSubscription) {
    console.log("Using existing SpaceChannel subscription");
    return spaceChannelSubscription;
  }

  const actionHandlers = {
    check_in: checkIn,
    update_scan_data: updateScanData,
    update_booking: updateBooking,
    update_guest: updateGuest,
    update_invite: updateInvite,
    update_booking_link: updateBookingLink,
    handle_payment: handlePayment
  };

  console.log("Creating new SpaceChannel subscription");
  spaceChannelSubscription = consumer.subscriptions.create({ channel: "SpaceChannel", id: id }, {
    connected() {},
    disconnected() {},
    received(data) {
      const handler = actionHandlers[data["method"]];
      if (handler) handler(data);
    },
  });

  return spaceChannelSubscription;
}

document.addEventListener("turbolinks:load", () => {
  const channelContainer = document.querySelector(".space-channel[data-channel-id]");

  if (channelContainer) {
    const id = channelContainer.dataset.channelId;
    createSpaceChannelSubscription(id);
  };
});

// @dev For testing purposes
export { consumer };