const initLocationToggler = () => {
  
  const toggleFields = () => {
    const checkbox = document.getElementById('online_location_checkbox');
    const onlineHostField = document.getElementById('online_host_field');
    const addressField = document.getElementById('address_field');

    if (checkbox) {  // Ensure the checkbox exists on the page
      if (checkbox.checked) {
        onlineHostField.style.display = 'block';
        addressField.style.display = 'none';
      } else {
        onlineHostField.style.display = 'none';
        addressField.style.display = 'block';
      }
    }
  };

  $(".svp-body").on("change", "#online_location_checkbox", toggleFields);

  // Initial toggle setting on page load
  toggleFields();
};

export { initLocationToggler };
