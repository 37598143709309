// REQUIRE STATEMENTS
require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();

require("jquery");
require("flatpickr");
require("chartkick");
require("chart.js");
require("select2");

// INTERNAL IMPORTS
import "channels";
import "controllers"; // Import Stimulus controllers

import { initAdminOrgFilter } from "../components/init_admin_org_filter";
import { initClickToCopy } from "../components/init_click_to_copy";
import initFileUpload from "../components/init_file_upload";
import initExpandResources from "../components/init_expand_resources";
import { initFormCounter } from "../components/init_form_counter";
import { initInfiniteScroll } from "../components/init_infinite_scroll";
import { initMetaFieldsInsert } from "../components/init_meta_fields_insert";
import { initModal } from "../components/init_modal";
import { initLazy } from "../components/init_lazy";
import { initAdminInlineEdits } from "../components/init_admin_inline_edits";
import { initOnboarding } from "../components/init_onboarding";
import { initAdminBookingCreation } from "../components/init_admin_booking_creation";
import { initRenderHtml } from "../components/init_render_html";
import { initRemoveStorage } from "../components/init_remove_storage";
import { initToggleTrigger } from "../components/init_toggle_trigger";
import { initSwitchTogglable } from "../components/init_switch_togglable";
import { initAnimateCount } from "../components/init_custom_animation";
import { initTinySpinner } from "../components/init_tiny_spinner";
initAnimateCount();
import { initCustomFormSwap } from "../components/init_custom_form_swap";
import { initSearchBar } from "../components/init_search_bar";
import { initBookingUploadForm } from "../components/init_booking_upload_form";
import { initNoteDisplay } from "../components/init_note_display";
import { initRunDownload } from "../components/init_run_download";
import { initBookingDownload } from "../components/init_booking_download";
import { initFlatpickr } from "../components/init_flatpickr";
import { initEventInviteForm } from "../components/init_event_invite_form";
import initFormWizard from "../components/init_form_wizard";
import { initLocationToggler } from "../components/init_location_toggler";
import { initProductPlaceholderForm } from "../components/init_product_placeholder_form";
import { initAudienceDisplay } from "../components/init_audience_display";
import { initAdminEventFilter } from "../components/init_admin_events_filter";
import { initTagFilter, initTagFilterWatcher } from "../components/init_tag_filter";
import { initMailingListInputs } from "../components/init_mailing_list_inputs";
import { initInviteEmail } from "../components/init_invite_email";
import { initExpandActivity } from "../components/init_expand_activity";
import { initSelectCheckbox } from "../components/init_select_checkbox";
import { initPassbookLink } from "../components/init_passbook_link";
import { initInviteParamFilters } from "../components/init_invite_param_filters";
import { initAnalyticsDropdowns } from "../components/init_analytics_dropdowns";
import { initGlobalSearch } from "../components/init_global_search";
import { initInlineSearch } from "../components/init_inline_search";
import { initBookingList } from "../components/init_booking_list";
import { initTimeZoneCookie } from "../components/init_time_zone_cookie";
import { initPortalEmail } from "../components/init_portal_email";
import { initCancelEmail } from "../components/init_cancel_email";
import { initCancelEventInput } from "../components/init_cancel_event_input";

// MODULE CALL
document.addEventListener("turbolinks:load", () => {
  initModal();
  initLazy();
  initAdminInlineEdits();
  initAdminBookingCreation();
  initOnboarding();
  initMetaFieldsInsert();
  initInfiniteScroll();
  initFormCounter();
  initRenderHtml();
  initRemoveStorage();
  initToggleTrigger();
  initFileUpload();
  initAdminOrgFilter();
  initTinySpinner();
  initAnimateCount();
  initCustomFormSwap();
  initSwitchTogglable();
  initSearchBar();
  initBookingUploadForm();
  initExpandResources();
  initNoteDisplay();
  initRunDownload();
  initBookingDownload();
  initFlatpickr();
  initEventInviteForm();
  initFormWizard();
  initLocationToggler();
  initProductPlaceholderForm();
  initAudienceDisplay();
  initAdminEventFilter();
  initTagFilter();
  initTagFilterWatcher();
  initMailingListInputs();
  initClickToCopy();
  initInviteEmail();
  initExpandActivity();
  initSelectCheckbox();
  initPassbookLink();
  initInviteParamFilters();
  initAnalyticsDropdowns();
  initGlobalSearch();
  initInlineSearch();
  initBookingList();
  initTimeZoneCookie();
  initPortalEmail();
  initCancelEmail();
  initCancelEventInput();
});
