export function displayFlashMessage(message, type) {
    const flashElement = document.createElement("p");
    flashElement.className = `notification ${type} alert-dismissible fade show m-1`;
    flashElement.setAttribute("data-close", "self");
    flashElement.setAttribute("data-notification", "active");
    flashElement.style.top = "8px";
    flashElement.style.animation = "1s ease 3s 1 normal none running slideout";
    flashElement.style.transition = "top 0.75s ease-in-out";
    flashElement.textContent = message;

    document.body.appendChild(flashElement);

    flashElement.addEventListener("animationend", () => {
        flashElement.remove();
    });
  }

