import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    console.log("Image Upload Controller connected");
    this.insertImageCropperModal();
  }

  insertImageCropperModal() {
    const modalHTML = `
      <div class="modal" id="crop-image-modal">
        <div class="modal-background"></div>
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="is-size-6 has-text-weight-bold">Adjust your image before uploading</p>
          </header>
          <section class="">
            <img id="cropped-image-preview" style="max-width: 100%; height: 400px; object-fit: contain;" />
          </section>
          <footer class="modal-card-foot is-flex is-justify-content-space-between">
            <div class="is-flex-grow-1"></div>
            <button class="button" id="crop-image-modal-close">Cancel</button>
            <button class="button is-success" id="crop-image-modal-crop">Crop</button>
          </footer>
        </div>
      </div>
    `;
    document.body.insertAdjacentHTML('beforeend', modalHTML);
  }

  handleFileChange(event) {
    const input = event.target;
    const logoFile = input.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      this.initializeModal(e.target.result, input);
    };

    reader.readAsDataURL(logoFile);
  }

  initializeModal(imageSrc, input) {
    const $imagePreview = $("#cropped-image-preview");
    const $modal = $("#crop-image-modal");
    const image = document.getElementById('cropped-image-preview');
    const photoUploader = $(input).closest(".js-photo-uploader");
    const isCircle = photoUploader.data("circle");

    $imagePreview.attr("src", imageSrc);
    $modal.addClass("is-active");

    const cropper = new Cropper(image, {
      aspectRatio: isCircle ? 1 : NaN,
      viewMode: 1,
      zoomable: false,
      scalable: false,
      autoCropArea: 1,
    });

    const handleModalClose = () => {
      cropper.destroy();
      $modal.removeClass("is-active");
      $(document).off("keydown", handleEscKey);
    };

    const handleCrop = () => {
      this.cropImage(cropper, photoUploader, isCircle, handleModalClose);
    };

    const handleEscKey = (event) => {
      if (event.key === "Escape") {
        handleModalClose();
      }
    };

    $(".svp-body").one("click", "#crop-image-modal-close", handleModalClose);
    $(".svp-body").one("click", "#crop-image-modal-crop", handleCrop);
    $(document).on("keydown", handleEscKey);
  }

  cropImage(cropper, photoUploader, isCircle, handleModalClose) {
    const canvas = cropper.getCroppedCanvas({
      // Removed fixed width and height to prevent scaling issues
      imageSmoothingQuality: 'high', // Improves image quality
    });

    canvas.toBlob((blob) => {
      const url = URL.createObjectURL(blob);
      const logoPlaceholder = photoUploader.find("#logo_placeholder");
      const uploadButton = photoUploader.find(".banner-upload-button");
      const logoFileName = photoUploader.find("#logo-file-name");

      logoPlaceholder.attr("src", url).show();
      logoFileName.html("Cropped Image");

      const fileInput = photoUploader.find("#logo-file")[0];
      const dataTransfer = new DataTransfer();
      const file = new File([blob], "cropped_image.png", { type: "image/png" });
      dataTransfer.items.add(file);
      fileInput.files = dataTransfer.files;

      uploadButton.find(".file-label").text(isCircle ? "Change" : "Change Banner");
      uploadButton.css({
        "bottom": "0%",
        "top": "unset",
      });

      if (photoUploader.data("auto-upload")) {
        notify_success("Uploading...");
        photoUploader.find("#js-photo-save").click();
        photoUploader.find("#logo-placeholder-filter").show();
      }

      handleModalClose();
    });
  }
}
