import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["downloads", "dots"];
  connect() {
    console.log("Connected invites download controller");
  }

  async download() {
    const inviteIds = this.downloadsTarget.getAttribute("data-invites");
    const parsedInviteIds = JSON.parse(inviteIds);
    const resource_id = this.downloadsTarget.getAttribute("data-resource");
    const scope = this.downloadsTarget.getAttribute("data-scope");
    this.dotsTarget.classList.remove("is-hidden");

    try {
      
      const res = await fetch(`/${resource_id}/download_invites`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": document
            .querySelector('meta[name="csrf-token"]')
            .getAttribute("content"),
        },
        body: JSON.stringify({
          invite_ids: parsedInviteIds,
          resource_id: resource_id,
        }),
      });
      const data = await res.json();
  
      if(data.download){
        notify_success(`Downloading ${scope} invites.\n\nThey will be emailed to ${data.email}`)
      } else {
        notify_danger("Download did not occur, please try again")
      }
    } catch (error) {
      notify_danger("Error, please try again.");
    } finally {
      this.dotsTarget.classList.add("is-hidden");
    }
  }
}
