import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["timeZoneSelect"];

  connect() {
    if (this.hasTimeZoneSelectTarget) {
      this.initialTimeZone = this.timeZoneSelectTarget.value;
    }
  }

  timeZoneChanged() {
    if (this.timeZoneSelectTarget.value !== this.initialTimeZone) {
      alert("If you change your time zone then your availability and all associated bookings will be updated to match the new time zone");
    }
  }
}
