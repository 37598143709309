import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["priceInput", "taxInput", "taxType", "priceDisplay", "taxDisplay", "taxTypeDisplay", "priceLabelCalc", "priceLabelFree", "priceLabelTax"];

  connect() {
    this.updatePrices();
    this.updateTaxType();
  }

  updatePrices() {
    const price = parseFloat(this.priceInputTarget.value) || 0;
    const taxRate = parseFloat(this.taxInputTarget.value) || 0;
    const tax = price * (taxRate / 100);

    this.priceDisplayTarget.textContent = price.toFixed(2);
    this.taxDisplayTarget.textContent = tax.toFixed(2);

    if (price == 0) {
      this.priceLabelFreeTarget.style.display = "inline";
      this.priceLabelCalcTarget.style.display = "none";
    } else {
      this.priceLabelFreeTarget.style.display = "none";
      this.priceLabelCalcTarget.style.display = "inline";
    }

    if (taxRate == 0) {
      this.priceLabelTaxTarget.style.display = "none";
    } else {
      this.priceLabelTaxTarget.style.display = "inline";
    }
  }

  updateTaxType() {
    this.taxTypeDisplayTarget.textContent = this.taxTypeTarget.value;
  }
}
